import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const home = (
  <Tooltip id="home" className="custom-tooltip">
    Dashboard
  </Tooltip>
);
const exchange = <Tooltip id="exchange">Hotkeys & Transaction</Tooltip>;
const accounts = <Tooltip id="accounts">Accounts</Tooltip>;
const data = <Tooltip id="data">Nominators</Tooltip>;
const subnets = <Tooltip id="data">Subnets</Tooltip>;
const miners = <Tooltip id="data">Miner & Validators</Tooltip>;
const consensus = <Tooltip id="data">Yama Consensus</Tooltip>;
const new_feat = <Tooltip id="data">Future Transactions</Tooltip>;
const settings = <Tooltip id="settings">Setting</Tooltip>;

function Sidebar() {
  return (
    <>
      <div className="sidebar">
        <div className="menu">
          <ul>
            <li>
              <Link
                to={"/dashboard"}
                className={
                  window.location.pathname.includes("/dashboard")
                    ? `active`
                    : ""
                }
              >
                <OverlayTrigger
                  style={{ color: "white" }}
                  placement="right"
                  overlay={home}
                >
                  <span>
                    <i className="mdi mdi-view-dashboard"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/hotkeys_txns"}
                className={
                  window.location.pathname.includes("/hotkeys_txns")
                    ? `active`
                    : ""
                }
              >
                <OverlayTrigger placement="right" overlay={exchange}>
                  <span>
                    <i className="mdi mdi-wallet"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            {/* <li>
              <Link
                to={"/accounts"}
                className={
                  window.location.pathname.includes("/accounts") ? `active` : ""
                }
              >
                <OverlayTrigger placement="right" overlay={accounts}>
                  <span>
                    <i className="mdi mdi-face-profile"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li> */}
            <li>
              <Link
                to={"/nominators"}
                className={
                  window.location.pathname.includes("/nominators")
                    ? `active`
                    : ""
                }
              >
                <OverlayTrigger placement="right" overlay={data}>
                  <span>
                    <i className="mdi mdi-database"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/subnets"}
                className={
                  window.location.pathname.includes("/subnets") ? `active` : ""
                }
              >
                <OverlayTrigger placement="right" overlay={subnets}>
                  <span>
                    <i className="mdi mdi-network"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/miners"}
                className={
                  window.location.pathname.includes("/miners") |
                  window.location.pathname.includes("/validators")
                    ? `active`
                    : ""
                }
              >
                <OverlayTrigger placement="right" overlay={miners}>
                  <span>
                    <i className="mdi mdi-circle"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/consensus"}
                className={
                  window.location.pathname.includes("/consensus")
                    ? `active`
                    : ""
                }
              >
                <OverlayTrigger placement="right" overlay={consensus}>
                  <span>
                    <i className="mdi mdi-image"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/new_feat"}
                className={
                  window.location.pathname.includes("/new_feat") ? `active` : ""
                }
              >
                <OverlayTrigger placement="right" overlay={new_feat}>
                  <span>
                    <i className="mdi mdi-sale"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
            <li>
              <Link
                to={"/settings"}
                className={
                  window.location.pathname.includes("/settings") ? `active` : ""
                }
              >
                <OverlayTrigger placement="right" overlay={settings}>
                  <span>
                    <i className="mdi mdi-settings"></i>
                  </span>
                </OverlayTrigger>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
