import React from "react";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";

import Footer from "./layout/footer";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";

function Consensus() {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="content-body" id="dashboard">
        Yama Consensus - comming soon!
      </div>
      <Footer />
    </>
  );
}

export default Consensus;
