// Function to convert data to CSV format
const convertToCSV = (data) => {
  const csvRows = [];

  // Get headers
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(",")); // join headers with a comma

  // Loop over the data to create CSV rows
  data.forEach((row) => {
    const values = headers.map((header) => {
      return row[header];
    });
    csvRows.push(values.join(",")); // join values with a comma
  });

  return csvRows.join("\n"); // Join all rows by a newline character
};

const getTimeStampFromBlockNumber = (blockNum) => {
  const baseBlockNum = 4214379;
  const baseTimeStamp = 1730990316001;

  const diffBlockNum = blockNum - baseBlockNum;

  const timestamp = baseTimeStamp + 12 * diffBlockNum * 1000;
  return timestamp;
};

module.exports = {
  convertToCSV,
  getTimeStampFromBlockNumber,
};
