import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Signin from "./pages/auth/signin";
import Signup from "./pages/auth/signup";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Accounts from "./pages/tabs/account/accounts";
import HotkeysTxns from "./pages/tabs/hotkeys_txns/hotkey_txns";
import TaoTxns from "./pages/tabs/hotkeys_txns/tao_transaction";
import Nominators from "./pages/tabs/nominators/nominators";
import Settings from "./pages/tabs/settings/settings";
import Subnets from "./pages/tabs/subnets/subnets";
import MinerValidators from "./pages/miner_validators";
import Consensus from "./pages/consensus";
import FutureTxns from "./pages/feat_txns";

import AuthProvider from "./context/AuthProvder";
import { ProtectedRoute } from "./ProtectedRoute";
import SubnetsMetagraph from "./pages/tabs/subnets/subnets_metagraph";
import NominatorEvents from "./pages/tabs/nominators/events";
import Logs from "./pages/tabs/settings/logs";
import Naming from "./pages/tabs/settings/naming";
import Miners from "./pages/tabs/miner_validators/miners";
import Validators from "./pages/tabs/miner_validators/validators";

class Index extends Component {
  render() {
    return (
      <>
        {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
        <AuthProvider>
          <Router>
            <div id="main-wrapper">
              <Routes>
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/accounts/:address" element={<Accounts />} />
                <Route path="/hotkeys_txns" element={<HotkeysTxns />} />
                <Route path="/hotkeys_txns/tao" element={<TaoTxns />} />
                <Route path="/nominators" element={<Nominators />} />
                <Route
                  path="/nominators/events"
                  element={<NominatorEvents />}
                />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/log" element={<Logs />} />
                <Route path="/settings/naming" element={<Naming />} />
                <Route path="/subnets" element={<Subnets />} />
                <Route
                  path="/subnets/metagraph"
                  element={<SubnetsMetagraph />}
                />
                <Route path="/miners" element={<Miners />} />
                <Route path="/validators" element={<Validators />} />
                <Route path="/consensus" element={<Consensus />} />
                <Route path="/new_feat" element={<FutureTxns />} />
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </div>
          </Router>
        </AuthProvider>
      </>
    );
  }
}

export default Index;
