import React from "react";
import { Link } from "react-router-dom";

function MinerValidatorSubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/miners"}
            className={
              window.location.pathname == "/miners"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-worker"></i>
            <span>Miners</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/validators"}
            className={
              window.location.pathname == "/validators"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-verified"></i>
            <span>Validators</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default MinerValidatorSubmenu;
