export const dataset = [
  {
    emission: 0.004,
    subnetID: "0",
  },
  {
    emission: 0.003,
    subnetID: "1",
  },
  {
    emission: 0.009,
    subnetID: "2",
  },
  {
    emission: 0.104,
    subnetID: "3",
  },
  {
    emission: 0.304,
    subnetID: "4",
  },
  {
    emission: 0.00444,
    subnetID: "5",
  },
  {
    emission: 0.0032,
    subnetID: "6",
  },
  {
    emission: 0.0021,
    subnetID: "7",
  },
  {
    emission: 0.000183,
    subnetID: "8",
  },
  {
    emission: 0.0004,
    subnetID: "9",
  },
  {
    emission: 0.001,
    subnetID: "10",
  },
  {
    emission: 0.00195,
    subnetID: "11",
  },
];

export function valueFormatter(value) {
  return `${value} T`;
}
