import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class TimeDatePicker extends React.Component {
  state = {
    startDate: new Date(),
  };

  render() {
    return (
      <DatePicker
        className="form-control"
        selected={this.props.date}
        onChange={this.props.onChange}
      />
    );
  }
}
export default TimeDatePicker;
