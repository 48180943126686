import React from "react";
import { Link } from "react-router-dom";

function AccountSubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/accounts"}
            className={
              window.location.pathname.includes("/accounts")
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-account"></i>
            <span>Account</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default AccountSubmenu;
