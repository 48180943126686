import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import Spinner from "jsx/components/Spinner";
import { raoToTao, shortenAddress } from "jsx/utils/validator";
import MinerValidatorSubmenu from "jsx/pages/layout/submenu_miner_validator";
import TickPlacementBars from "jsx/components/barchart/barchart";
import { getTimeStampFromBlockNumber, convertToCSV } from "jsx/utils/utils";

function Miners() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalSubnets, setTotalSubnets] = useState(0);
  const [subnetPages, setSubnetPages] = useState(1);
  const [currentSubnetPage, setCurrentSubnetPage] = useState(1);
  const [subnets, setSubnets] = useState([]);
  const [detailMiner, SetDetailMiner] = useState();

  // filter states
  const [searchSubnetID, setSearchSubnetID] = useState("");
  const [searchIPAddress, setSearchIPAddress] = useState("");
  const [searchUID, setSearchUID] = useState("");
  const [searchHotKey, setSearchHotKey] = useState("");
  const [searchColdKey, setSearchColdKey] = useState("");
  const [searchIsActive, setSearchIsActive] = useState("");
  const [searchIsValidator, setSearchIsValidator] = useState("false");
  const [searchIsImmunity, setSearchIsImmunity] = useState(0);
  const [order, setOrder] = useState("emission_desc");
  const [pageSize, setPageSize] = useState("50");
  const [minerIncentiveCurve, setMinerIncentiveCurve] = useState([]);

  // Dialog
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  useEffect(() => {
    clickSearch();
  }, []);

  const changeHotkeyPage = (event, value) => {
    setCurrentSubnetPage(value);
    doSearch(value);
  };

  const clickSearch = async () => {
    setCurrentSubnetPage(1);
    doSearch(1);
  };

  const doSearch = async (pageNum) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subnets/info`,
        {
          subnet_id: searchSubnetID,
          ip_addr: searchIPAddress,
          neuron_id: searchUID,
          hotkey: searchHotKey,
          coldkey: searchColdKey,
          is_active: searchIsActive,
          is_immunity: searchIsImmunity,
          is_validator: searchIsValidator,
          order: order,
          pageNum: pageNum,
          pageSize: searchSubnetID ? 1024 : pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      if (searchSubnetID) {
        loadEmissionDataset(response.data.data);
      } else {
        loadEmissionDataset([]);
      }

      setSubnets(response.data.data);
      setTotalSubnets(response.data.pagination.total_items);
      setSubnetPages(response.data.pagination.total_pages);
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);

      toast.error("Failed to get the data from server", { theme: "dark" });
    }
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`Copied the text!`, { theme: "dark" });
      })
      .catch((err) => {
        toast.error(`Failed to copy`, { theme: "dark" });
      });
  };

  const loadEmissionDataset = (subnets) => {
    var sortedDatasets = [];
    subnets
      .sort((a, b) => {
        return a.incentive - b.incentive;
      })
      .forEach((subnet) => {
        sortedDatasets.push({
          emission: Number(subnet.incentive), // raoToTao(subnet.emission),
          subnetID: subnet.uid,
        });
      });

    setMinerIncentiveCurve(sortedDatasets);
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subnets/info`,
        {
          subnet_id: searchSubnetID,
          ip_addr: searchIPAddress,
          neuron_id: searchUID,
          hotkey: searchHotKey,
          coldkey: searchColdKey,
          is_active: searchIsActive,
          is_immunity: searchIsImmunity,
          is_validator: searchIsValidator,
          order: order,
          pageNum: 1,
          pageSize: 1024,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      var formatedDatas = [];
      var gindex = 0;

      const unformatedData = response.data.data;

      unformatedData.forEach((miner, index) => {
        var formatedData = {
          No: gindex,
          SubnetID: miner.netuid,
          NeuronID: miner.uid,
          HotKey: miner.hotkey.ss58,
          ColdKey: miner.coldkey.ss58,
          Active: miner.active,
          Incentive: miner.incentive,
          Emission: raoToTao(miner.emission) + "T",
          Stake: raoToTao(miner.stake) + "T",
          Reward: raoToTao(miner.daily_reward) + "T",
          RegisterdAtBlock: miner.registered_at_block,
          IP: miner.axon ? miner.axon.ip : "",
          Port: miner.axon ? miner.axon.port : "",
          AxonVersion: miner.axon ? miner.axon.version : "",
          Rank: miner.rank,
          RegisteredTimeStamp:
            new Date(
              getTimeStampFromBlockNumber(miner.registered_at_block)
            ).toLocaleDateString() +
            " " +
            new Date(
              getTimeStampFromBlockNumber(miner.registered_at_block)
            ).toLocaleTimeString(),
        };
        gindex++;
        formatedDatas.push(formatedData);
      });

      const csvData = convertToCSV(formatedDatas); // Convert data to CSV
      const blob = new Blob([csvData], { type: "text/csv" }); // Create a blob from CSV
      const url = window.URL.createObjectURL(blob); // Create a download link from blob

      // Create a link element, set the download attribute, and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `miners_on_subnet_${searchSubnetID}.csv`; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const handleOpenDetailDialog = (miner) => {
    console.log(miner);
    SetDetailMiner(miner);
    setOpenDetailDialog(true);
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <MinerValidatorSubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Miner Information</h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                There are{" "}
                                {
                                  <b style={{ color: "greenyellow" }}>
                                    {totalSubnets}
                                  </b>
                                }{" "}
                                accounts
                              </div>
                            </div>
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Subnet ID</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="SubnetID"
                                      value={searchSubnetID}
                                      onChange={(e) =>
                                        setSearchSubnetID(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">IP Address</span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="IP Address"
                                      value={searchIPAddress}
                                      onChange={(e) =>
                                        setSearchIPAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">UID(NeuronID)</span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="UID(NeuronID)"
                                      value={searchUID}
                                      onChange={(e) =>
                                        setSearchUID(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">Hot Key</span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Hot Key"
                                      value={searchHotKey}
                                      onChange={(e) =>
                                        setSearchHotKey(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">Cold Key</span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Cold Key"
                                      value={searchColdKey}
                                      onChange={(e) =>
                                        setSearchColdKey(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-1 mb-2">
                                    <span className="fs-11">Active</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={searchIsActive}
                                      onChange={(e) =>
                                        setSearchIsActive(e.target.value)
                                      }
                                    >
                                      <option value="">All</option>
                                      <option value="true">True</option>
                                      <option value="false">False</option>
                                    </select>
                                  </div>

                                  {/* <div className="col-md-1 mb-2">
                                    <span className="fs-11">Validator</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={searchIsValidator}
                                      onChange={(e) =>
                                        setSearchIsValidator(e.target.value)
                                      }
                                    >
                                      <option value="">All</option>
                                      <option value="true">True</option>
                                      <option value="false">False</option>
                                    </select>
                                  </div> */}
                                  <div className="col-md-1 mb-2">
                                    <span className="fs-11">Immunity</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={searchIsImmunity}
                                      onChange={(e) =>
                                        setSearchIsImmunity(e.target.value)
                                      }
                                    >
                                      <option value="">All</option>
                                      <option value="true">True</option>
                                      <option value="false">False</option>
                                    </select>
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Order</span>
                                    <select
                                      className="form-control select"
                                      name="country"
                                      value={order}
                                      onChange={(e) => setOrder(e.target.value)}
                                    >
                                      <option value="uid_asc">uid asc</option>
                                      <option value="uid_desc">uid desc</option>
                                      <option value="emission_asc">
                                        emission asc
                                      </option>
                                      <option value="emission_desc">
                                        emission desc
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 mb-2">
                                    <span className="fs-11">Page Size</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={pageSize}
                                      onChange={(e) =>
                                        setPageSize(e.target.value)
                                      }
                                    >
                                      <option value="20">PageSize - 20</option>
                                      <option value="50">PageSize - 50</option>
                                      <option value="100">
                                        PageSize - 100
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-auto offset-md-1 text-end">
                                    <button
                                      className="btn btn-dark waves-effect small mt-4"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button
                                      className="btn btn-dark waves-effect mt-4"
                                      onClick={handleDownloadCSV}
                                    >
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2 mb-2 m-auto p-auto">
                              <div className="col-md-12 justify-content-sm-center">
                                <Pagination
                                  count={subnetPages}
                                  color="primary"
                                  onChange={changeHotkeyPage}
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              {minerIncentiveCurve.length > 0 && (
                                <TickPlacementBars
                                  dataset={minerIncentiveCurve}
                                  label="Miner Incentive"
                                  xLabel="Neuron ID"
                                />
                              )}
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">Subnet ID</th>
                                <th className="text-center">Neuron ID</th>
                                <th className="text-center">Hot Key</th>
                                <th className="text-center">Cold Key</th>
                                <th className="text-center">Active</th>
                                <th className="text-center">Incentive</th>
                                <th className="text-center">Emission</th>
                                <th className="text-center">Stake</th>
                                <th className="text-center">Reward</th>
                                <th className="text-center">
                                  Registered Block
                                </th>
                                <th className="text-center">Timestamp</th>
                                <th className="text-center">More</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subnets.map((subnet, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">
                                      {Number(currentSubnetPage - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td className="text-center">
                                      {subnet.netuid}
                                    </td>
                                    <td className="text-center">
                                      {subnet.uid}
                                    </td>
                                    <td className="text-center fs-11">
                                      <Link
                                        to={`/accounts/${subnet.hotkey.ss58}`}
                                        className="link-addr"
                                      >
                                        {subnet.hotkey.name
                                          ? shortenAddress(subnet.hotkey.name)
                                          : shortenAddress(subnet.hotkey.ss58)}
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(subnet.hotkey.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center fs-11">
                                      <Link
                                        to={`/accounts/${subnet.coldkey.ss58}`}
                                        className="link-addr"
                                      >
                                        {subnet.coldkey.name
                                          ? shortenAddress(subnet.coldkey.name)
                                          : shortenAddress(subnet.coldkey.ss58)}
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(subnet.coldkey.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {subnet.active ? (
                                        <span className="text-success">O</span>
                                      ) : (
                                        <span className="text-failed">O</span>
                                      )}
                                    </td>
                                    <td className="text-center fs-11">
                                      {Number(subnet.incentive).toFixed(8)}
                                    </td>
                                    <td className="text-center fs-11">
                                      {raoToTao(subnet.emission)} T
                                    </td>
                                    <td className="text-center fs-11">
                                      {raoToTao(subnet.stake)} T
                                    </td>
                                    <td className="text-center fs-11">
                                      {raoToTao(subnet.daily_reward)} T
                                    </td>
                                    <td className="text-center fs-11">
                                      {subnet.registered_at_block}
                                    </td>
                                    <td className="text-center fs-11">
                                      {new Date(
                                        getTimeStampFromBlockNumber(
                                          subnet.registered_at_block
                                        )
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          getTimeStampFromBlockNumber(
                                            subnet.registered_at_block
                                          )
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        onClick={(e) =>
                                          handleOpenDetailDialog(subnet)
                                        }
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />

      <Modal
        open={openDetailDialog}
        onClose={handleCloseDetailDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#131722",
            border: "1px solid #303030",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="p" component="h4">
            Extension Information
          </Typography>

          <div className="row mt-4 mb-2">
            <div className="col-6">
              <div className="fs-11">Axon IP</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={
                  detailMiner
                    ? detailMiner.axon
                      ? detailMiner.axon.ip
                      : "No IP"
                    : ""
                }
              />
            </div>
            <div className="col-6">
              <div className="fs-11">Axon Port</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={
                  detailMiner
                    ? detailMiner.axon
                      ? detailMiner.axon.port
                      : "No Port"
                    : ""
                }
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Axon IP Type</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={
                  detailMiner
                    ? detailMiner.axon
                      ? detailMiner.axon.ipType
                      : "No IP Type"
                    : ""
                }
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Axon Protocol</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={
                  detailMiner
                    ? detailMiner.axon
                      ? detailMiner.axon.protocol
                      : "No Protocol"
                    : ""
                }
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Axon Version</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={
                  detailMiner
                    ? detailMiner.axon
                      ? detailMiner.axon.version
                      : "No Version"
                    : ""
                }
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Dividends</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={detailMiner ? detailMiner.dividends : ""}
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Consensus</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={detailMiner ? detailMiner.consensus : ""}
              />
            </div>
            <div className="col-6 mt-1">
              <div className="fs-11">Child Key</div>
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                readOnly={true}
                value={detailMiner ? detailMiner.is_child_key : ""}
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenDetailDialog(false)}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Miners;
