import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import axios from "axios";

import Footer from "../../layout/footer";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import Spinner from "../../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import AccountSubmenu from "jsx/pages/layout/submenu_account";
import { raoToTao, shortenAddress, taoToRao } from "jsx/utils/validator";
import { convertToCSV } from "jsx/utils/utils";

function Accounts() {
  const navigate = useNavigate();
  const { address } = useParams();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [searchAddress, setSearchAddress] = useState("");

  const [account, setAccount] = useState("");

  const [totalTxns, setTotalTxns] = useState(0);
  const [currentTxPage, setCurrentTxPage] = useState(1);
  const [txns, setTxns] = useState([]);
  const [txnPages, setTxnPages] = useState(0);

  const [totalEvents, setTotalEvents] = useState(0);
  const [eventPages, setEventPages] = useState(0);
  const [currentEventPage, setCurrentEventPage] = useState(0);
  const [events, setEvents] = useState([]);

  const [pageSize, setPageSize] = useState("5");

  useEffect(() => {
    setSearchAddress(address);
    getAccount(address);
  }, [address]);

  useEffect(() => {
    fnWithLoading(getTaoTxns);
  }, [currentTxPage]);

  useEffect(() => {
    fnWithLoading(getDelegateTxns);
  }, [currentEventPage]);

  const fnWithLoading = async (fn) => {
    setLoading(true);
    await fn(address);
    setLoading(false);
  };

  const getAccount = async (address) => {
    setLoading(true);
    await getAccountInfo(address);
    await getTaoTxns(address);
    await getDelegateTxns(address);
    setLoading(false);
  };

  const getAccountInfo = async (address) => {
    try {
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(true);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/account/list`,
        {
          addr: address,
          order: "balance_total_desc",
          pageNum: 1,
          pageSize: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data.data);
      if (response.data.data.length != 1) {
        toast.error("Could not find an account", {
          theme: "dark",
          containerId: 1,
        });
      } else {
        setAccount(response.data.data[0]);
      }
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      toast.error("Could not find an account", {
        theme: "dark",
        containerId: 1,
      });
    }
  };

  const getTaoTxns = async (address) => {
    try {
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/account/txn`,
        {
          addr: address,
          pageNum: currentTxPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setTxns(response.data.data);
      setTotalTxns(response.data.pagination.total_items);
      setTxnPages(response.data.pagination.total_pages);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
    }
  };

  const getDelegateTxns = async (address) => {
    try {
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/nominators/events`,
        {
          addr: address,
          // delegate_address: address,
          order: "block_number_desc",
          pageNum: currentEventPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEvents(response.data.data);
      setTotalEvents(response.data.pagination.total_items);
      setEventPages(response.data.pagination.total_pages);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
    }
  };

  const clickSearch = () => {
    navigate(`/accounts/${searchAddress}`);
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`Copied the text!`, { theme: "dark" });
      })
      .catch((err) => {
        toast.error(`Failed to copy`, { theme: "dark" });
      });
  };

  const changeTxPage = (event, value) => {
    setCurrentTxPage(value);
  };

  const changeEventPages = (event, value) => {
    setCurrentEventPage(value);
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      await getAccountInfo(address);
      await getTaoTxns(address);
      await getDelegateTxns(address);
      setLoading(false);

      var formatedDatas = [];
      var gindex = 0;

      console.log(account);

      var accountInfo = {
        Address: account.address.ss58,
        BalanceFree: raoToTao(account.balance_free) + " T",
        BalanceStaked: raoToTao(account.balance_staked) + " T",
        BalanceTotal: raoToTao(account.balance_total) + " T",
        CreatedAt: account.created_on_date,
        Rank: account.rank,
      };

      formatedDatas.push(accountInfo);

      const csvData = convertToCSV(formatedDatas); // Convert data to CSV
      const csvTxnHeader = ", \n\n TAO Transaction History, \n";

      formatedDatas = [];

      txns.forEach((txn) => {
        gindex++;
        formatedDatas.push({
          No: gindex,
          From: txn.from.ss58,
          To: txn.to.ss58,
          Amount: raoToTao(txn.amount) + " T",
          CreatedAt:
            new Date(txn.timestamp).toLocaleDateString() +
            " " +
            new Date(txn.timestamp).toLocaleTimeString(),
        });
      });

      const csvData_txns = convertToCSV(formatedDatas); // Convert data to CSV
      const csvEventsHeader = ", \n\n TAO Events, \n";

      formatedDatas = [];

      events.forEach((event) => {
        gindex++;
        formatedDatas.push({
          No: gindex,
          From: event.from.ss58,
          To: event.to.ss58,
          Amount: raoToTao(event.amount) + " T",
          CreatedAt:
            new Date(event.timestamp).toLocaleDateString() +
            " " +
            new Date(event.timestamp).toLocaleTimeString(),
        });
      });

      if (events.length == 0) {
        formatedDatas = [{ Data: "Nothing" }];
      }

      const csvData_events = convertToCSV(formatedDatas); // Convert data to CSV

      const blob = new Blob(
        [csvData, csvTxnHeader, csvData_txns, csvEventsHeader, csvData_events],
        {
          type: "text/csv",
        }
      ); // Create a blob from CSV
      const url = window.URL.createObjectURL(blob); // Create a download link from blob

      // Create a link element, set the download attribute, and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `account_${address}.csv`; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <AccountSubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Account: <span className="fs-11">{address}</span>
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-5">
                                    <span className="fs-11">Address</span>
                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Address"
                                      value={searchAddress}
                                      onChange={(e) =>
                                        setSearchAddress(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="col-md-auto offset-md-1 text-end">
                                    <button
                                      className="btn btn-dark waves-effect small mt-4"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button
                                      className="btn btn-dark waves-effect mt-4"
                                      onClick={handleDownloadCSV}
                                    >
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="price-grid" id="price">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-wallet fs-4"></i>
                          <div className="flex-grow-1 badge badge-success">
                            Free
                          </div>
                        </div>
                        {/* <p className="mb-0"> 24h</p> */}
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center pb-3">
                          <div className="flex-grow-1">
                            {raoToTao(account.balance_free)} T
                          </div>
                          {/* <div>3232</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-wallet fs-4"></i>
                          <div className="flex-grow-1 badge badge-type2">
                            Staked
                          </div>
                        </div>
                        {/* <p className="mb-0"> 24h</p> */}
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center pb-3">
                          <div className="flex-grow-1">
                            {raoToTao(account.balance_staked)} T
                          </div>
                          {/* <div>3232</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-chart-bar-stacked fs-4"></i>
                          <div className="flex-grow-1">Rank</div>
                        </div>
                        {/* <p className="mb-0"> 24h</p> */}
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center pb-3">
                          <div className="flex-grow-1">{account.rank}</div>
                          {/* <div>3232</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <Tab.Container defaultActiveKey="limit">
                <div className="card">
                  <div className="card-header">
                    <Nav variant="pills">
                      <Nav.Link eventKey="limit">Transfer</Nav.Link>
                      <Nav.Link eventKey="market">Delegate</Nav.Link>
                    </Nav>
                  </div>
                  <PerfectScrollbar
                    options={{ suppressScrollX: true }}
                    className="custom-scrollbar"
                  >
                    <div className="card-body market-limit">
                      <Tab.Content>
                        <Tab.Pane eventKey="limit">
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">From</th>
                                <th className="text-center">To</th>
                                <th className="text-center">Amount</th>
                                <th className="text-center">Fee</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Tx Hash</th>
                              </tr>
                            </thead>
                            <tbody>
                              {txns.map((txn, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">
                                      {(Number(currentTxPage) - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/accounts/${txn.from.ss58}`}
                                        className="link-addr"
                                      >
                                        <span
                                          className={
                                            txn.from.ss58 == address
                                              ? "text-warning"
                                              : ""
                                          }
                                        >
                                          {txn.from.name
                                            ? shortenAddress(txn.from.name)
                                            : shortenAddress(txn.from.ss58)}
                                        </span>
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.from.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/accounts/${txn.to.ss58}`}
                                        className="link-addr"
                                      >
                                        <span
                                          className={
                                            txn.to.ss58 == address
                                              ? "text-warning"
                                              : ""
                                          }
                                        >
                                          {txn.to.name
                                            ? shortenAddress(txn.to.name)
                                            : shortenAddress(txn.to.ss58)}
                                        </span>
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.to.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {raoToTao(txn.amount)} T
                                    </td>
                                    <td className="text-center">
                                      {raoToTao(txn.fee)} T
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        txn.timestamp
                                      ).toLocaleDateString()}{" "}
                                      -{" "}
                                      {new Date(
                                        txn.timestamp
                                      ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {shortenAddress(
                                        txn.transaction_hash,
                                        10,
                                        4
                                      )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.transaction_hash);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className="row justify-content-center mt-4">
                            <div className="col-md-auto">
                              <Pagination
                                count={txnPages}
                                Txn
                                color="primary"
                                onChange={changeTxPage}
                              />
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="market">
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nominator Address</th>
                                <th>Delegate Address</th>
                                <th>Amount</th>
                                <th>Action</th>
                                <th>Timestamp</th>
                                <th>Block Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {events.map((event, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {event.nominator_address.name
                                        ? event.nominator_address.name
                                        : shortenAddress(
                                            event.nominator_address.ss58
                                          )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(
                                            event.nominator_address.ss58
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td>
                                      {event.delegate_address.name
                                        ? event.delegate_address.name
                                        : shortenAddress(
                                            event.delegate_address.ss58
                                          )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(
                                            event.delegate_address.ss58
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td>{raoToTao(event.amount)} T</td>
                                    <td>
                                      <span
                                        className={
                                          event.action == "UNDELEGATE"
                                            ? "badge badge-type2"
                                            : "badge badge-success"
                                        }
                                      >
                                        {event.action}
                                      </span>
                                    </td>
                                    <td>
                                      {new Date(
                                        event.timestamp
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          event.timestamp
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td>{event.block_number}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className="row justify-content-center mt-4">
                            <div className="col-md-auto">
                              <Pagination
                                count={eventPages}
                                color="primary"
                                onChange={changeEventPages}
                              />
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </PerfectScrollbar>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Accounts;
