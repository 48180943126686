import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import Spinner from "jsx/components/Spinner";
import { raoToTao, shortenAddress } from "jsx/utils/validator";
import NominatorSubmenu from "jsx/pages/layout/submenu_nominator";
import TimeDatePicker from "jsx/components/datepicker";

function NominatorEvents() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalEvents, setTotalEvents] = useState(0);
  const [eventPages, setEventPages] = useState(0);
  const [currentEventPage, setCurrentEventPage] = useState(0);
  const [events, setEvents] = useState([]);

  // filter states
  const [searchAddr, setSearchAddr] = useState("");
  const [searchDelegateAddress, setSearchDelegateAddress] = useState("");
  const [searchBlockStart, setSearchBlockStart] = useState("");
  const [searchBlockEnd, setSearchBlockEnd] = useState("");
  const [order, setOrder] = useState("amount_desc");
  const [pageSize, setPageSize] = useState("50");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");

  useEffect(() => {
    clickSearch();
  }, [currentEventPage]);

  const changeHotkeyPage = (event, value) => {
    setCurrentEventPage(value);
  };

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/nominators/events`,
        {
          addr: searchAddr,
          delegate_address: searchDelegateAddress,
          block_start: searchBlockStart,
          block_end: searchBlockEnd,
          start_date: searchStartDate,
          end_date: searchEndDate,
          order: order,
          pageNum: currentEventPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setEvents(response.data.data);
      setTotalEvents(response.data.pagination.total_items);
      setEventPages(response.data.pagination.total_pages);

      if (currentEventPage > response.data.pagination.total_pages) {
        setCurrentEventPage(1);
      }

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const handleStartDateChange = (date) => {
    setSearchStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSearchEndDate(date);
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`Copied the text!`, { theme: "dark" });
      })
      .catch((err) => {
        toast.error(`Failed to copy`, { theme: "dark" });
      });
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <NominatorSubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Hotkeys</h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                There are{" "}
                                {
                                  <b style={{ color: "greenyellow" }}>
                                    {totalEvents}
                                  </b>
                                }{" "}
                                accounts
                              </div>
                            </div>
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-4">
                                    <span className="fs-11">Address</span>
                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Address"
                                      value={searchAddr}
                                      onChange={(e) =>
                                        setSearchAddr(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-4">
                                    <span className="fs-11">
                                      Delegate Address
                                    </span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Delegate Address"
                                      value={searchDelegateAddress}
                                      onChange={(e) =>
                                        setSearchDelegateAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Block Start</span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Block Start"
                                      value={searchBlockStart}
                                      onChange={(e) =>
                                        setSearchBlockStart(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Block End</span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Block End"
                                      value={searchBlockEnd}
                                      onChange={(e) =>
                                        setSearchBlockEnd(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Start Date</span>

                                    <div>
                                      <TimeDatePicker
                                        date={searchStartDate}
                                        onChange={handleStartDateChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">End Date</span>

                                    <div>
                                      <TimeDatePicker
                                        date={searchEndDate}
                                        onChange={handleEndDateChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Order</span>
                                    <select
                                      className="form-control select"
                                      name="country"
                                      value={order}
                                      onChange={(e) => setOrder(e.target.value)}
                                    >
                                      <option value="amount_asc">
                                        Amount ASC
                                      </option>
                                      <option value="amount_desc">
                                        Amount DESC
                                      </option>
                                      <option value="block_number_asc">
                                        Block Number ASC
                                      </option>
                                      <option value="block_number_desc">
                                        Block Number DESC
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 mb-2">
                                    <span className="fs-11">Page Size</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={pageSize}
                                      onChange={(e) =>
                                        setPageSize(e.target.value)
                                      }
                                    >
                                      <option value="20">PageSize - 20</option>
                                      <option value="50">PageSize - 50</option>
                                      <option value="100">
                                        PageSize - 100
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-auto offset-md-1 text-end">
                                    <button
                                      className="btn btn-dark waves-effect small mt-4"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button className="btn btn-dark waves-effect mt-4">
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2 mb-2">
                              <div className="col-md-12 justify-content-sm-center">
                                <Pagination
                                  count={eventPages}
                                  color="primary"
                                  onChange={changeHotkeyPage}
                                />
                              </div>
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nominator Address</th>
                                <th>Delegate Address</th>
                                <th>Amount</th>
                                <th>Action</th>
                                <th>Timestamp</th>
                                <th>Block Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {events.map((event, index) => {
                                return (
                                  <tr>
                                    <td>
                                      {(Number(currentEventPage) - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/accounts/${event.nominator.ss58}`}
                                        className="link-addr"
                                      >
                                        {event.nominator.name
                                          ? shortenAddress(event.nominator.name)
                                          : shortenAddress(
                                              event.nominator.ss58
                                            )}
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(event.nominator.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td>
                                      {event.delegate.name
                                        ? shortenAddress(event.delegate.name)
                                        : shortenAddress(
                                            event.delegate.ss58
                                          )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(event.delegate.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td>{raoToTao(event.amount)} T</td>
                                    <td>
                                      <span
                                        className={
                                          event.action == "UNDELEGATE"
                                            ? "badge badge-type2"
                                            : "badge badge-success"
                                        }
                                      >
                                        {event.action}
                                      </span>
                                    </td>
                                    <td>
                                      {new Date(
                                        event.timestamp
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          event.timestamp
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td>{event.block_number}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default NominatorEvents;
