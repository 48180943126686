import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { valueFormatter } from "./weather";

var chartSetting = {
  yAxis: [
    {
      label: "emission (T)",
      labelStyle: { fill: "#FFFFFF" },
    },
  ],
  series: [
    {
      dataKey: "emission",
      label: "Emission",
      valueFormatter,
      labelStyle: { fill: "#FFFFFF" },
    },
  ],
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

export default function TickPlacementBars({ dataset, label, xLabel }) {
  const [tickPlacement, setTickPlacement] = React.useState("middle");
  const [tickLabelPlacement, setTickLabelPlacement] = React.useState("middle");

  chartSetting.series[0].label = label ? label : chartSetting.series[0].label;

  return (
    <div style={{ width: "100%" }}>
      <BarChart
        dataset={dataset}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "subnetID",
            tickPlacement,
            tickLabelPlacement,
            labelStyle: { fill: "#FFFFFF" }, // Custom label color for x-axis
            tickStyle: { fill: "#FFFFFF", fontSize: "12px" }, // Custom tick style for y-axis
            label: xLabel,
          },
        ]}
        {...chartSetting}
      />
    </div>
  );
}
