import React from "react";
import { Link } from "react-router-dom";

function HotkeySubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/hotkeys_txns"}
            className={
              window.location.pathname == "/hotkeys_txns"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-wallet"></i>
            <span>HotKeys</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/hotkeys_txns/tao"}
            className={
              window.location.pathname == "/hotkeys_txns/tao"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-book-open"></i>
            <span>Transaction</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default HotkeySubmenu;
