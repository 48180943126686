function validateName(name) {
  const nameRegex = /^[A-Za-z]+(?:[ -][A-Za-z]+)*$/;
  const isValidFormat = nameRegex.test(name);
  const isValidLength = name.length >= 2 && name.length <= 50;

  return isValidFormat && isValidLength;
}

function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

function validatePassword(password) {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

// Function to convert RAO to TAO
function raoToTao(raoAmount) {
  const conversionFactor = 1e9; // 1 TAO = 1,000,000,000 RAO
  return raoAmount / conversionFactor;
}

function taoToRao(taoAmount) {
  const conversionFactor = 1e9;
  return taoAmount * conversionFactor;
}

// Function to shorten a TAO address
function shortenAddress(address, startChars = 6, endChars = 4) {
  // If the address is too short to shorten, return it as-is
  if (address.length <= startChars + endChars) {
    return address;
  }

  // Shorten the address by showing first few and last few characters
  return `${address.substring(0, startChars)}...${address.substring(
    address.length - endChars
  )}`;
}

module.exports = {
  validateName,
  validateEmail,
  validatePassword,
  raoToTao,
  taoToRao,
  shortenAddress,
};
