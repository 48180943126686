import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";

import Footer from "./layout/footer";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/signin");
      return;
    }
  }, []);
  return (
    <>
      <Header />
      <Sidebar />
      <div className="content-body" id="dashboard">
        Dashboard - comming soon!
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">BTC - USD Perpetual Swap</h4>
                  <span>
                    24h Change <strong className="text-success"> +5.30%</strong>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row  justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">24h Volume</p>
                      <h6>814,107.51 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Index Price</p>
                      <h6>10,326.19 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Mark Price</p>
                      <h6>10,343.94 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Last Price</p>
                      <h6>10,383.51 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Funding Rate</p>
                      <h6>0.1392%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Your Wallet</h4>
                  <span>
                    Update <span>10</span> minutes ago
                  </span>
                </div>
                <div className="card-body">
                  <div className="row justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Equity</p>
                      <h6>34.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Available Margin</p>
                      <h6>47.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Unrealized P&L</p>
                      <h6>92.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Position Margin</p>
                      <h6>58.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Active Orders</p>
                      <h6>15.00 USD</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>*/}
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
