import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  validateEmail,
  validateName,
  validatePassword,
} from "../../utils/validator";

import Spinner from "../../components/Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file

function Signup() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {}, []);

  const onSignUpClicked = (e) => {
    e.preventDefault();
    let valid = true;
    if (!validateName(username)) {
      setNameError("Please enter a valid name");
      valid = false;
    } else {
      setNameError("");
    }
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!validatePassword(password)) {
      setPasswordError(
        "Please enter a valid password at least 8 characters with upper, lower, speical and number characters"
      );
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      // Create a new post with the input data
      setLoading(true);
      axios
        .post(`${apiUrl}/api/auth/signup`, {
          username: username,
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.data.result == true) {
            setLoading(false);
            toast.success("You account created!", {
              autoClose: 3000, // Auto close after 3 seconds
            });
            navigate("/signin");
          } else {
            console.log(response);
            setLoading(false);
            toast.error(response.data.err, {
              autoClose: 3000, // Auto close after 3 seconds
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Couldn't create your account!", {
            autoClose: 3000, // Auto close after 3 seconds
          });
        });
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"./"}>
                  <img
                    src={require("../../../images/logo.png")}
                    alt=""
                    height={25}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign up your account</h4>
                </div>
                <div className="card-body">
                  <form method="post" name="myform" className="signup_validate">
                    <div className="mb-3">
                      <label>Username</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                    </div>
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && (
                        <p style={{ color: "red" }}>{emailError}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError && (
                        <p style={{ color: "red" }}>{passwordError}</p>
                      )}
                    </div>
                    <div className="text-center mt-4">
                      <button
                        className="btn btn-success btn-block"
                        onClick={onSignUpClicked}
                      >
                        Sign up
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Already have an account?{" "}
                      <Link className="text-primary" to={"/signin"}>
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Signup;
