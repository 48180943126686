import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import Spinner from "jsx/components/Spinner";
import HotkeySubmenu from "jsx/pages/layout/submenu_hotkey";
import { raoToTao, shortenAddress, taoToRao } from "jsx/utils/validator";
import { convertToCSV } from "jsx/utils/utils";

function HotkeysTxns() {
  const navigate = useNavigate();

  const [loadingComment, setLoadingComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalHotkeys, setTotalHotkeys] = useState(0);
  const [hotkeyPages, setHotkeyPages] = useState(0);
  const [currentHotkeyPage, setCurrentHotkeyPage] = useState(1);
  const [hotkeys, setHotkeys] = useState([]);

  // filter states
  const [searchAddr, setSearchAddr] = useState("");
  const [searchMinFreeBalance, setSearchMinFreeBalance] = useState(0);
  const [searchMaxFreeBalance, setSearchMaxFreeBalance] = useState(0);
  const [searchMinStakedBalance, setSearchMinStakedBalance] = useState(0);
  const [searchMaxStakedBalance, setSearchMaxStakedBalance] = useState(0);
  const [searchMinTotalBalance, setSearchMinTotalBalance] = useState(0);
  const [searchMaxTotalBalance, setSearchMaxTotalBalance] = useState(0);
  const [order, setOrder] = useState("balance_total_desc");
  const [pageSize, setPageSize] = useState("50");

  useEffect(() => {}, []);

  useEffect(() => {
    clickSearch();
  }, [currentHotkeyPage]);

  const changeHotkeyPage = (event, value) => {
    setCurrentHotkeyPage(value);
  };

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/account/list`,
        {
          addr: searchAddr,
          min_free_balance: taoToRao(searchMinFreeBalance),
          max_free_balance: taoToRao(searchMaxFreeBalance),
          min_staked_balance: taoToRao(searchMinStakedBalance),
          max_staked_balance: taoToRao(searchMaxStakedBalance),
          min_total_balance: taoToRao(searchMinTotalBalance),
          max_total_balance: taoToRao(searchMaxTotalBalance),
          order: order,
          pageNum: currentHotkeyPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      setHotkeys(response.data.data);
      setTotalHotkeys(response.data.pagination.total_items);
      setHotkeyPages(response.data.pagination.total_pages);
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const copyToClipboard = (textToCopy) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success(`Copied the text!`, { theme: "dark" });
        })
        .catch((err) => {
          toast.error(`Failed to copy`, { theme: "dark" });
        });
    } else {
      toast.error(
        `Clipboard API not supported. To enable this, your website must be use https`,
        { theme: "dark" }
      );
    }
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var formatedDatas = [];
      var gindex = 0;

      // for (var i = 1; i < totalHotkeys / 100; i++) {
      for (var i = 1; i <= 10; i++) {
        var response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/account/list`,
          {
            addr: searchAddr,
            min_free_balance: taoToRao(searchMinFreeBalance),
            max_free_balance: taoToRao(searchMaxFreeBalance),
            min_staked_balance: taoToRao(searchMinStakedBalance),
            max_staked_balance: taoToRao(searchMaxStakedBalance),
            min_total_balance: taoToRao(searchMinTotalBalance),
            max_total_balance: taoToRao(searchMaxTotalBalance),
            order: order,
            pageNum: i,
            pageSize: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLoadingComment(`Getting ${i} page`);

        const unformatedData = response.data.data;

        unformatedData.forEach((data, index) => {
          var formatedData = {
            No: gindex,
            Address: data.address.ss58,
            FreeBalance: raoToTao(data.balance_free),
            StakedBalance: raoToTao(data.balance_staked),
            TotalBalance: raoToTao(data.balance_total),
            Rank: data.rank,
          };
          gindex++;
          formatedDatas.push(formatedData);
        });
      }

      setLoadingComment(``);

      const csvData = convertToCSV(formatedDatas); // Convert data to CSV
      const blob = new Blob([csvData], { type: "text/csv" }); // Create a blob from CSV
      const url = window.URL.createObjectURL(blob); // Create a download link from blob

      // Create a link element, set the download attribute, and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "hotkeys.csv"; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} comment={loadingComment} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <HotkeySubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Hotkeys</h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                There are{" "}
                                {
                                  <b style={{ color: "greenyellow" }}>
                                    {totalHotkeys}
                                  </b>
                                }{" "}
                                accounts
                              </div>
                            </div>
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-4">
                                    <span className="fs-11">Address</span>
                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Address"
                                      value={searchAddr}
                                      onChange={(e) =>
                                        setSearchAddr(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Min free balance
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Min Free Balance"
                                      value={searchMinFreeBalance}
                                      onChange={(e) =>
                                        setSearchMinFreeBalance(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Max free balance
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Max Free Balance"
                                      value={searchMaxFreeBalance}
                                      onChange={(e) =>
                                        setSearchMaxFreeBalance(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Min staked balance
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Min Stake Balance"
                                      value={searchMinStakedBalance}
                                      onChange={(e) =>
                                        setSearchMinStakedBalance(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Max staked balance
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Max Stake Balance"
                                      value={searchMaxStakedBalance}
                                      onChange={(e) =>
                                        setSearchMaxStakedBalance(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Min total balance
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Min Total Balance"
                                      value={searchMinTotalBalance}
                                      onChange={(e) =>
                                        setSearchMinTotalBalance(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Max total balance
                                    </span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Max Total Balance"
                                      value={searchMaxTotalBalance}
                                      onChange={(e) =>
                                        setSearchMaxTotalBalance(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Order</span>
                                    <select
                                      className="form-control select"
                                      name="country"
                                      value={order}
                                      onChange={(e) => setOrder(e.target.value)}
                                    >
                                      <option value="balance_free_asc">
                                        balance free asc
                                      </option>
                                      <option value="balance_free_desc">
                                        balance free desc
                                      </option>
                                      <option value="balance_staked_asc">
                                        balance staked asc
                                      </option>
                                      <option value="balance_staked_desc">
                                        balance staked desc
                                      </option>
                                      <option value="balance_total_asc">
                                        balance total asc
                                      </option>
                                      <option value="balance_total_desc">
                                        balance total desc
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 mb-2">
                                    <span className="fs-11">Page Size</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={pageSize}
                                      onChange={(e) =>
                                        setPageSize(e.target.value)
                                      }
                                    >
                                      <option value="20">PageSize - 20</option>
                                      <option value="50">PageSize - 50</option>
                                      <option value="100">
                                        PageSize - 100
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-auto offset-md-1 text-end">
                                    <button
                                      className="btn btn-dark waves-effect small mt-4"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button
                                      className="btn btn-dark waves-effect mt-4"
                                      onClick={handleDownloadCSV}
                                    >
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2 mb-2">
                              <div className="col-md-12 justify-content-sm-center">
                                <Pagination
                                  count={hotkeyPages}
                                  color="primary"
                                  onChange={changeHotkeyPage}
                                />
                              </div>
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Address</th>
                                <th>Free Balance</th>
                                <th>Staked Balance</th>
                                <th>Total Balance</th>
                                <th>Rank</th>
                                <th>Block Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {hotkeys.map((account, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {(Number(currentHotkeyPage) - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/accounts/${account.address.ss58}`}
                                        className="link-addr"
                                      >
                                        {account.address.name
                                          ? shortenAddress(account.address.name)
                                          : shortenAddress(
                                              account.address.ss58
                                            )}
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(account.address.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          Number(account.balance_free) > 0
                                            ? "text-success"
                                            : ""
                                        }
                                      >
                                        {raoToTao(account.balance_free)}
                                      </span>{" "}
                                      T
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          Number(account.balance_staked) > 0
                                            ? "text-success"
                                            : ""
                                        }
                                      >
                                        {raoToTao(account.balance_staked)}
                                      </span>{" "}
                                      T
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          Number(account.balance_total) > 0
                                            ? "text-success"
                                            : ""
                                        }
                                      >
                                        {raoToTao(account.balance_total)}
                                      </span>{" "}
                                      T
                                    </td>
                                    <td>{account.rank}</td>
                                    <td>{account.block_number}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default HotkeysTxns;
