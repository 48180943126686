import React from "react";

function Spinner({ isVisible, comment }) {
  return (
    <div id="preloader" className={`${isVisible ? "visible" : "fade-out"}`}>
      <div className="sk-three-bounce">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
        <div className="sk-comment">{comment}</div>
      </div>
    </div>
  );
}

export default Spinner;
