import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";

import axios from "axios";

import Spinner from "jsx/components/Spinner";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import SubnetSubmenu from "jsx/pages/layout/submenu_subnet";
import { raoToTao } from "jsx/utils/validator";
import TickPlacementBars from "jsx/components/barchart/barchart";
import { convertToCSV } from "jsx/utils/utils";

function Subnets() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalSubnets, setTotalSubnets] = useState(0);
  const [searchSubnetID, setSearchSubnetID] = useState("");
  const [subnets, setSubnets] = useState([]);

  const [subnetEmissionDataset, setSubnetEmissionDataset] = useState([]);
  const [sortedSnEmissionDataset, setSortedSnEmissionDataset] = useState([]);

  useEffect(() => {
    clickSearch();
  }, []);

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subnets`,
        {
          subnet_id: searchSubnetID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      loadEmissionDataset(response.data.data);
      setSubnets(response.data.data);
      setTotalSubnets(response.data.pagination.total_items);
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const loadEmissionDataset = (subnets) => {
    var datasets = [];
    subnets
      .sort((a, b) => {
        return a.netuid - b.netuid;
      })
      .forEach((subnet) => {
        datasets.push({
          emission: raoToTao(subnet.emission),
          subnetID: subnet.netuid,
        });
      });

    setSubnetEmissionDataset(datasets);

    var sortedDatasets = [];
    subnets
      .sort((a, b) => {
        return b.emission - a.emission;
      })
      .forEach((subnet, index) => {
        sortedDatasets.push({
          emission: raoToTao(subnet.emission),
          y: raoToTao(subnet.emission),
          x: index,
          subnetID: subnet.netuid,
        });
      });

    setSortedSnEmissionDataset(sortedDatasets);
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subnets`,
        {
          subnet_id: searchSubnetID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      var formatedDatas = [];
      var gindex = 0;

      const unformatedData = response.data.data;

      unformatedData.forEach((subnet, index) => {
        var formatedData = {
          No: gindex,
          SubnetID: subnet.netuid,
          Emission: raoToTao(subnet.emission),
          Total_Recycled: raoToTao(subnet.recycled_since_registration),
          Recycled_24H: raoToTao(subnet.recycled_24_hours),
          Register_Cost: raoToTao(subnet.registration_cost),
          Neuron_Reg_Cost: raoToTao(subnet.neuron_registration_cost),
          Max_Neurons: subnet.max_neurons,
          Max_Validators: subnet.max_validators,
          Active_Validators: subnet.active_validators,
          Active_Miners: subnet.active_miners,
          Owner: subnet.owner.ss58,
          Registered_at:
            new Date(subnet.registration_timestamp).toLocaleDateString() +
            " " +
            new Date(subnet.registration_timestamp).toLocaleTimeString(),
        };
        gindex++;
        formatedDatas.push(formatedData);
      });

      const csvData = convertToCSV(formatedDatas); // Convert data to CSV
      const blob = new Blob([csvData], { type: "text/csv" }); // Create a blob from CSV
      const url = window.URL.createObjectURL(blob); // Create a download link from blob

      // Create a link element, set the download attribute, and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "subnets.csv"; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <SubnetSubmenu />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Subnets</h4>
                  </div>
                  <div className="card-body">
                    <div className="transaction-table">
                      <div className="table-responsive">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col">
                              There are{" "}
                              {
                                <b style={{ color: "greenyellow" }}>
                                  {totalSubnets}
                                </b>
                              }{" "}
                              subnets
                            </div>
                          </div>
                          <div className="row filter">
                            <div className="col-xl-12">
                              <div className="row">
                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Subnet ID</span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="SubnetID"
                                    value={searchSubnetID}
                                    onChange={(e) =>
                                      setSearchSubnetID(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="col-md-auto offset-md-1 text-end">
                                  <button
                                    className="btn btn-dark waves-effect small mt-4"
                                    onClick={clickSearch}
                                  >
                                    <i className="mdi mdi-account-search"></i>
                                    <span> Search</span>
                                  </button>
                                  <button
                                    className="btn btn-dark waves-effect mt-4"
                                    onClick={handleDownloadCSV}
                                  >
                                    <i className="mdi mdi-file-export"></i>
                                    <span> Export CSV</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <TickPlacementBars
                              dataset={subnetEmissionDataset}
                              label="Emission Graph"
                              xLabel="Subnet ID"
                            />
                          </div>
                          <div className="row mt-3">
                            {/* <GridDemo dataset={sortedSnEmissionDataset} /> */}
                            <TickPlacementBars
                              dataset={sortedSnEmissionDataset}
                              label="Sorted Emission Graph"
                              xLabel="Subnet ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="price-grid" id="price">
            <div className="container">
              <div className="row">
                {subnets
                  .sort((a, b) => {
                    return b.emission - a.emission;
                  })
                  .map((subnet) => {
                    return (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                          <div className="card-header">
                            <div className="d-flex align-items-center">
                              {/* <span>
                              <i className="cc BTC"></i>
                            </span> */}
                              <div className="flex-grow-1 tooltip-inner">
                                {subnet.name
                                  ? subnet.name + `(UID-${subnet.netuid})`
                                  : `SN-${subnet.netuid}`}
                              </div>
                            </div>
                            <p className="mb-0"> 24h</p>
                          </div>
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Emission `}</div>
                              <div>{raoToTao(subnet.emission)}</div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Total Recycled `}</div>
                              <div>
                                {raoToTao(subnet.recycled_since_registration)}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Recycled 24h `}</div>
                              <div>{raoToTao(subnet.recycled_24_hours)}</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Register Cost `}</div>
                              <div>{raoToTao(subnet.registration_cost)}</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Neuron Reg Cost `}</div>
                              <div>
                                {raoToTao(subnet.neuron_registration_cost)}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Difficulty`}</div>
                              <div>
                                <span className="text-secondary">
                                  {subnet.difficulty}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Bonds Moving Avg `}</div>
                              <div>{subnet.bonds_moving_avg}</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Max Neurons`}</div>
                              <div>
                                <span className="text-warning">
                                  {subnet.max_neurons}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Max Validators`}</div>
                              <div>
                                <span className="text-warning">
                                  {subnet.max_validators}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Validators`}</div>
                              <div>
                                <span className="text-success">
                                  {subnet.active_validators}
                                </span>
                                /{subnet.validators}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">{`Miners`}</div>
                              <div>
                                <span className="text-success">
                                  {subnet.active_miners}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Subnets;
