import React from "react";
import { Link } from "react-router-dom";

function NominatorSubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/nominators"}
            className={
              window.location.pathname == "/nominators"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-wallet"></i>
            <span>Nominators</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/nominators/events"}
            className={
              window.location.pathname == "/nominators/events"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-book-open"></i>
            <span>Events</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default NominatorSubmenu;
