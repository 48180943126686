import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import axios from "axios";

import Spinner from "jsx/components/Spinner";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import { raoToTao } from "jsx/utils/validator";
import SettingsSubmenu from "jsx/pages/layout/submenu_settings";
import { toast, ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#131722",
  border: "1px solid #303030",
  boxShadow: 24,
  p: 4,
};

function Settings() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalUsers, setTotalUsers] = useState(0);
  const [searchNameOrEmail, setSearchNameOrEmail] = useState("");
  const [users, setUsers] = useState([]);

  const [searchUserStatus, setSearchUserStatus] = useState("");
  const [searchUserRole, setSearchUserRole] = useState("");
  const [pageSize, setPageSize] = useState("50");
  const [userPage, setUserPage] = useState(0);
  const [currentUserPage, setCurrentUserPage] = useState(0);

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  const [editUserName, setEditUserName] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");
  const [editUserRole, setEditUserRole] = useState("");
  const [editUserVerified, setEditUserVerified] = useState("");

  useEffect(() => {
    clickSearch();
  }, [currentUserPage]);

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            search: searchNameOrEmail,
            user_role: searchUserRole,
            user_verified: searchUserStatus,
            page_num: currentUserPage,
            page_size: pageSize,
          },
        }
      );
      console.log(response);
      setUsers(response.data.users);
      setTotalUsers(response.data.total);
      setUserPage(
        Math.floor(Number(response.data.total) / Number(pageSize)) + 1
      );

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const changeUserPage = (e) => {
    setCurrentUserPage(e.target.value);
  };

  const handleCloseDeleteUserModal = () => setOpenDelete(false);
  const handleCloseEditUserModal = () => setOpenEdit(false);

  const handleEditUser = (user) => {
    setOpenEdit(true);
    setSelectedUser(user);

    setEditUserName(user.user_name);
    setEditUserEmail(user.email);
    setEditUserPassword(user.password);
    setEditUserRole(user.role);
    setEditUserVerified(user.verified);
  };
  const handleDeleteUser = (user) => {
    setOpenDelete(true);
    setSelectedUser(user);
  };

  const handleConfirmDeleteUser = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            email: selectedUser.email,
          },
        }
      );
      console.log(response);
      await clickSearch();
      setOpenDelete(false);
      setLoading(false);

      toast.success(`${selectedUser.email} was removed successfully`, {
        theme: "dark",
      });
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }

      setLoading(false);
      toast.error(`${selectedUser.email} was not removed`, {
        theme: "dark",
      });
    }
  };

  const handleConfirmEditUser = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      const data = {
        email: selectedUser.email,
        name: editUserName,
        password: editUserPassword,
        role: editUserRole,
        verified: editUserVerified,
      };

      var response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await clickSearch();
      setOpenEdit(false);
      setLoading(false);

      toast.success(`${selectedUser.email} was updated successfully`, {
        theme: "dark",
      });
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }

      setLoading(false);
      toast.error(`${selectedUser.email} was not updated`, {
        theme: "dark",
      });
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <SettingsSubmenu />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Users</h4>
                  </div>
                  <div className="card-body">
                    <div className="transaction-table">
                      <div className="table-responsive">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col">
                              There are{" "}
                              {
                                <b style={{ color: "greenyellow" }}>
                                  {totalUsers}
                                </b>
                              }{" "}
                              users
                            </div>
                          </div>
                          <div className="row filter">
                            <div className="col-xl-12">
                              <div className="row">
                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Name or Email</span>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="Name or Email"
                                    value={searchNameOrEmail}
                                    onChange={(e) =>
                                      setSearchNameOrEmail(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">User Role</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={searchUserRole}
                                    onChange={(e) =>
                                      setSearchUserRole(e.target.value)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">User Status</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={searchUserStatus}
                                    onChange={(e) =>
                                      setSearchUserStatus(e.target.value)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="true">Verified</option>
                                    <option value="false">Not Verified</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Page Size</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={pageSize}
                                    onChange={(e) =>
                                      setPageSize(e.target.value)
                                    }
                                  >
                                    <option value="20">PageSize - 20</option>
                                    <option value="50">PageSize - 50</option>
                                    <option value="100">PageSize - 100</option>
                                  </select>
                                </div>

                                <div className="col-md-auto offset-md-1 text-end">
                                  <button
                                    className="btn btn-dark waves-effect small mt-4"
                                    onClick={clickSearch}
                                  >
                                    <i className="mdi mdi-account-search"></i>
                                    <span> Search</span>
                                  </button>
                                  <button className="btn btn-dark waves-effect mt-4">
                                    <i className="mdi mdi-file-export"></i>
                                    <span> Export CSV</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2 mb-2">
                            <div className="col-md-6 offset-md-5">
                              <Pagination
                                count={userPage}
                                color="primary"
                                onChange={changeUserPage}
                              />
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Email</th>
                                <th className="text-center">Role</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Created At</th>
                                <th className="text-center">Updated At</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.map((user, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">
                                      {user.user_name}
                                    </td>
                                    <td className="text-center">
                                      {user.email}
                                    </td>
                                    <td className="text-center">
                                      <span className="badge badge-success">
                                        {user.role}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      {user.verified ? (
                                        <span className="badge badge-success">
                                          Verified
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          Unverified
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        user.created_at
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          user.created_at
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        user.updated_at
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          user.updated_at
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {" "}
                                      <Link
                                        className="googlePlus text-center"
                                        to={"#"}
                                        onClick={() => handleEditUser(user)}
                                      >
                                        <span>
                                          <i className="mdi mdi-account-edit"></i>{" "}
                                          Edit
                                        </span>
                                      </Link>
                                      {" | "}
                                      <Link
                                        className="googlePlus text-center"
                                        to={"#"}
                                        onClick={(e) => handleDeleteUser(user)}
                                      >
                                        <span>
                                          <i className="mdi mdi-delete"></i>{" "}
                                          Delete
                                        </span>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        open={openDelete}
        onClose={handleCloseDeleteUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure to delete this account?
          </Typography>
          <div className="text-center mt-4">
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleConfirmDeleteUser}
            >
              Yes
            </Button>
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenDelete(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEditUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit User
          </Typography>

          <div className="row mt-3 mb-2">
            <div className="col-4">User Name</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                value={editUserName}
                onChange={(e) => setEditUserName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Email</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Email"
                readOnly={true}
                value={editUserEmail}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Password</div>
            <div className="col-8">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                readOnly={true}
                value={editUserPassword}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-4">User Role</div>
            <div className="col-8">
              <select
                className="form-control"
                name="country"
                value={editUserRole}
                onChange={(e) => setEditUserRole(e.target.value)}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-4">Verified</div>
            <div className="col-8">
              <label className="toggle">
                <input
                  className="toggle-checkbox"
                  type="checkbox"
                  checked={editUserVerified}
                  onChange={(e) => {
                    setEditUserVerified(e.target.checked);
                  }}
                />
                <span className="toggle-switch"></span>
                <span className="toggle-label"></span>
              </label>
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleConfirmEditUser}
            >
              Yes
            </Button>
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenEdit(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Settings;
