import React from "react";
import { Link } from "react-router-dom";

function SubnetSubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/subnets"}
            className={
              window.location.pathname == "/subnets"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-wallet"></i>
            <span>Subnets</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/subnets/metagraph"}
            className={
              window.location.pathname == "/subnets/metagraph"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-book-open"></i>
            <span>Subnet Meta Data</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default SubnetSubmenu;
