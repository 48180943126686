import { Navigate } from "react-router-dom";
import useAuth from "./context/useAuth";

export function ProtectedRoute({ element }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect them to the login page.
    return <Navigate to="/signin" />;
  }

  // If the user is authenticated, render the route's element.
  return element;
}
